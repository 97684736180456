module.exports = {
  // Ecommerce Platform
  platform: 'vtex',

  // Platform specific configs for API
  api: {
    storeId: 'partnernetwork',
    environment: 'vtexcommercestable',
    hideUnavailableItems: true,
  },

  // Default channel
  channel: '{"salesChannel":"1"}',

  // Production URLs
  storeUrl: 'https://partnerportal.vtex.com',
  secureSubdomain: 'https://partnerportal.vtex.com',
  checkoutUrl: '',
  loginUrl: '',
  accountUrl: '',

  // Lighthouse CI
  lighthouse: {
    server: process.env.BASE_SITE_URL || 'http://localhost:8000',
    pages: {
      home: '/',
      pdp: '/insider/p',
      collection: '/parceiros',
    },
  },

  // E2E CI
  cypress: {
    pages: {
      home: '/',
      pdp: '/insider/p',
      collection: '/parceiros',
      collection_filtered:
        '/office/?category-1=office&marca=acer&facets=category-1%2Cmarca',
      search: '/s?q=orange',
    },
  },

  analytics: {
    // https://developers.google.com/tag-platform/tag-manager/web#standard_web_page_installation,
    gtmContainerId: 'GTM-TLHV8756',
  },
}
