import Logo from 'src/components/ui/Logo'
import { mark } from 'src/sdk/tests/mark'
import { graphql, useStaticQuery } from 'gatsby'

import useResponsive from '../../../sdk/responsive/useResponsive'
import './footer.scss'

function Footer() {
  const data = useStaticQuery(
    graphql`
      query FooterQuery {
        cmsFooter {
          footerContent {
            footerLogo {
              logoImage
              logoSVGCode
              logoURL
            }
            institutional {
              instItems {
                instText
                instURL
              }
            }
            socialMedia {
              socialItems {
                socialImage
                socialURL
              }
            }
          }
        }
      }
    `
  )

  const { footerLogo } = data.cmsFooter.footerContent
  const { socialItems } = data.cmsFooter.footerContent.socialMedia
  const { instItems } = data.cmsFooter.footerContent.institutional
  const { isPortrait } = useResponsive()

  return (
    <footer
      className={`${
        isPortrait && 'footer-mobile'
      } footer layout__content-full partner-color-light-gray bg-partner-color-dark`}
    >
      <section className="footer-section layout__content flex a-center">
        <div className="footer-logo flex flex-1">
          <Logo
            logoImage={footerLogo.logoImage}
            logoSVGCode={footerLogo.logoSVGCode}
            logoURL={footerLogo.logoURL}
          />
        </div>
        <div className="flex flex-col">
          <ul className="flex py-0-5 j-end">
            {socialItems.map(
              (item: { socialImage: string; socialURL: string }) => {
                return (
                  <li className="px-0-5" key={item.socialImage}>
                    <a href={item.socialURL}>
                      <img src={item.socialImage} alt="Test" />
                    </a>
                  </li>
                )
              }
            )}
          </ul>
          <ul className="institutional-list flex py-0-5 j-end">
            {instItems.map((item: { instText: string; instURL: string }) => {
              return (
                <li key={item.instText} className="px-0-5">
                  <a
                    href={item.instURL}
                    className="partner-color-neutral-t no-decoration"
                  >
                    <span>{item.instText}</span>
                  </a>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </footer>
  )
}

Footer.displayName = 'Footer'

export default mark(Footer)
