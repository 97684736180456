import { List as UIList, Link } from '@faststore/ui'
import './submenu.scss'
import { useMediaQuery } from 'react-responsive'

interface SubmenuLinksType {
  linkText: string
  linkURL: string
}

interface SeeMoreType {
  seeMoreLink: string
  seeMoreText: string
}

interface SubmenuType {
  links: SubmenuLinksType[]
  seeMore: SeeMoreType
  title: string
}

interface Props {
  submenu: SubmenuType[]
}

function SubmenuLinks(props: Props) {
  const { submenu } = props

  const isBigScreen = useMediaQuery({ query: '(min-width: 1080px)' })

  return (
    <div className={`flex ${isBigScreen ? 'flex-row px-3' : 'flex-col'} w-100`}>
      {submenu.map((item, index) => {
        return (
          <div
            key={index}
            className={`${
              isBigScreen && 'submenu-list-container'
            } flex flex-col`}
          >
            <h3 className="submenu-title submenu-p-bottom secondary-title">
              {item.title}
            </h3>
            <UIList className="submenu-p-bottom">
              {item.links.map((listItem, listIndex) => {
                return (
                  <li className="submenu-link-item" key={listIndex}>
                    <Link
                      className="submenu-item-color no-decoration"
                      href={listItem.linkURL}
                    >
                      {listItem.linkText}
                    </Link>
                  </li>
                )
              })}
            </UIList>
            <Link
              className="submenu-p-bottom submenu-item-color"
              href={item.seeMore.seeMoreLink}
            >
              {item.seeMore.seeMoreText}
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default SubmenuLinks
