interface Props {
  logoImage: string
  logoSVGCode: string
  logoURL: string
}

function Logo(props: Props) {
  const { logoImage, logoSVGCode, logoURL } = props

  if (logoSVGCode) {
    return (
      <a href={logoURL}>
        <img
          src={`data:image/svg+xml;utf8,${logoSVGCode}`}
          alt="VTEX Partner Portal"
        />
      </a>
    )
  }

  return (
    <a href={logoURL}>
      <img
        src={logoImage}
        alt="VTEX Partner Portal"
        style={{ width: '97px', height: '37px' }}
      />
    </a>
  )
}

export default Logo
