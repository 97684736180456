import { useMediaQuery } from 'react-responsive'

function useResponsive() {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)',
  })

  const isBigScreen = useMediaQuery({ query: '(min-width: 1080px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1079px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  return {
    isBigScreen,
    isDesktopOrLaptop,
    isPortrait,
    isRetina,
    isTabletOrMobile,
  }
}

export default useResponsive
