import { List as UIList, Link } from '@faststore/ui'
import { graphql, useStaticQuery } from 'gatsby'
import SearchInput from 'src/components/common/SearchInput'
import Logo from 'src/components/ui/Logo'
import Submenu from 'src/components/ui/Submenu'
import { mark } from 'src/sdk/tests/mark'
import './navbar.scss'
import { useState } from 'react'
import useResponsive from 'src/sdk/responsive/useResponsive'

import CloseIcon from '../../ui/Icon/CloseIcon'
import MenuIcon from '../../ui/Icon/MenuIcon'

function Navbar() {
  const data = useStaticQuery(
    graphql`
      query HeaderQuery {
        cmsHeader {
          navigationBar {
            logo {
              logoImage
              logoSVGCode
              logoURL
            }
            menu {
              menuHeaderTitle
              submenu {
                links {
                  linkText
                  linkURL
                }
                seeMore {
                  seeMoreLink
                  seeMoreText
                }
                title
              }
            }
          }
        }
      }
    `
  )

  const { logo } = data.cmsHeader.navigationBar
  const { menu } = data.cmsHeader.navigationBar
  const [burger, setBurger] = useState(true)
  const { isBigScreen, isTabletOrMobile } = useResponsive()

  const navbarClass = isTabletOrMobile ? `navbar-mobile` : `navbar-desktop `
  const burgerClass = !burger ? `burger` : ''

  function handleBurger() {
    setBurger(!burger)
  }

  function MainMenu() {
    return (
      <nav className={`${navbarClass} ${burgerClass} nav-header`}>
        <UIList className="flex navbar-uilist">
          <li className="main-list-item navbar-item-color navbar-menu-main-option px-1">
            <h4 className="main-list-item-title">{menu.menuHeaderTitle}</h4>
            <div className="submenu-wrapper navbar-submenu-box">
              <Submenu {...menu} />
            </div>
          </li>
          <li>
            <Link
              className="nav-links navbar-item-color"
              href="https://apps.vtex.com/"
            >
              AppStore
            </Link>
          </li>
          <li>
            <Link
              className="nav-links navbar-item-color"
              href="https://vtex.com/br-pt/partner/"
            >
              Seja um parceiro
            </Link>
          </li>
        </UIList>
      </nav>
    )
  }

  return (
    <>
      {isBigScreen && (
        <header className="nav-header flex flex-row a-center px-1 overflow-v z-999999 position-r">
          <div className="nav-header-div nav-header-logo">
            <Logo {...logo} />
          </div>
          <div className="nav-header-div nav-header-list">
            <MainMenu />
          </div>
          <div className="nav-header-div nav-header-search">
            <SearchInput />
          </div>
        </header>
      )}
      {isTabletOrMobile && (
        <header className="flex flex-col a-center j-between overflow-v position-r">
          <div className="nav-header flex flex-row a-center j-between w-100 position-r z-999999">
            <div className="nav-header-div nav-header-logo p-1">
              <Logo {...logo} />
            </div>
            <nav className="p-1 bg-partner-color-darkest">
              <button
                className="b-none background-none"
                onClick={() => handleBurger()}
              >
                {burger ? <MenuIcon /> : <CloseIcon />}
              </button>
              <MainMenu />
            </nav>
          </div>
          <div className="w-100 p-1">
            <SearchInput />
          </div>
        </header>
      )}
    </>
  )
}

Navbar.displayName = 'Navbar'

export default mark(Navbar)
